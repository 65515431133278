<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f; overflow: hidden; "
                min-height="280">
          <v-card-text class="grey lighten-4 px-8 pb-5 pt-6">
            <v-form v-model="formValid" ref="formFillData">
              <v-row>
                <v-col cols="12" class="pb-1">
                  <v-select
                      label="Показники для заповнення" :items="indicators"
                      v-model="selectedIndicators" multiple
                      hide-details
                      dense color="grey" item-color="grey darken-1"
                      clearable
                  />
                </v-col>
                <v-col cols="12" md="4" class="pt-1">
                  <date-component v-model="create_date" :filled="false" label="Дата для створення" req/>
                </v-col>
                <v-col cols="12" md="4" class="pt-1">
                  <date-component v-model="registration_date" :filled="false" label="Дата реєстрації"/>
                </v-col>
                <v-col cols="12" md="4" class="pt-1">
                  <v-select
                      label="Вид реєстрації" :items="registrationTypeSelect"
                      v-model="registration_type"
                      hide-details
                      height="100%"
                      color="grey" item-color="grey darken-1"
                      clearable
                      :rules="[v => !!v || 'Це поле є обов’язковим']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text class="px-8 pb-0 d-flex">
            <MainModal
                :main="{ component: 'FillAddress', title: 'Заповнення по адресі' }"
                :button="{
                          icon: false,
                          color: 'grey lighten-3',
                          buttonIcon: 'mdi-filter',
                          buttonDepressed: true,
                          buttonName: 'Заповнити',
                          buttonIconClass: 'mr-2',
                          buttonIconColor: 'success'
                         }"
                :item="Object.assign({}, fillAddressConfig)"
                @crud="onFinishAddressFill"
            />
            <div class="py-2 px-4"
                 style="margin-left: 10px; flex: 1; background-color: #eaf6ea; color: #4caf50; font-weight: 400; font-size: .92rem">
              Для зміни додаткових показників - оберіть їх у списку показів та заповніть таблицю. Дані у таблиці зберігаються автоматично.
            </div>
          </v-card-text>
          <v-card-text class="pt-4 pb-2 px-8">
            <div class="table-without-lines" style="overflow: auto">
              <div class="table-header">
                <div class="table-col"
                     style="flex: 0 0 50px; text-align: left; padding-left: 10px;">
                  №
                </div>
                <div class="table-col" style="flex: 0 0 340px">Адреса</div>
                <div class="table-col" style="flex: 0 0 260px">Власник</div>
                <div class="table-col" style="flex: 0 0 120px">Мобільний</div>
                <div class="table-col" style="flex: 0 0 200px">Email</div>
                <div class="table-col"
                     style="flex: 0 0 80px; text-align: left;"
                     v-for="(c, i) in cols" :key="i"
                >
                  {{ (c.name).trim() }}
                </div>
              </div>
              <div class="table-body">
                <div class="table-row"
                     v-for="(item, idx) in filtered_items"
                     :key="item.row_num"
                >
                  <div class="table-col" style="flex: 0 0 50px; text-align: left; padding-left: 10px">
                    {{ item.row_num }}
                  </div>
                  <div class="table-col" style="flex: 0 0 340px; font-style: normal; color: #2d2d2d; padding-top: 8px;">
                    {{ item.address }}
                  </div>
                  <div class="table-col" style="flex: 0 0 260px; display: flex">
                    <v-icon :color="item.owner ? 'success' : 'error lighten-1'" size="22" class="mr-2">
                      mdi-account
                    </v-icon>
                    <input type="text" v-model="item.owner" @change="changeData(item, 'owner')">
                  </div>
                  <div class="table-col" style="flex: 0 0 120px">
                    <input type="text" v-model="item.phone" @change="changeData(item, 'phone')" :disabled="!item.owner_id">
                  </div>
                  <div class="table-col" style="flex: 0 0 200px">
                    <input type="text" v-model="item.email" @change="changeData(item, 'email')" :disabled="!item.owner_id">
                  </div>
                  <div class="table-col"
                       style="flex: 0 0 80px"
                       v-for="(c, i) in cols" :key="`${idx}-${i}`"
                  >
                    <input type="text" v-model.number="item[c.col]" v-decimal @change="changeData(item, c.col)">
                  </div>
                </div>
              </div>
            </div>
            <div class="pay-table-pagination d-flex justify-start" v-if="items.length">
              <div class="pa-3 d-flex align-center justify-start">
                <v-btn text depressed icon class="mr-1"
                       @click.stop="pageStartOrEnd(true)"
                       :disabled="items_per_page > items.length || page === 1"
                >
                  <v-icon>mdi-chevron-double-left</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="changePage(true)"
                       :disabled="page === 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="mx-2">
                  <v-chip>
                    {{ `Сторінка: ${page} із ${pages}` }}
                  </v-chip>
                </div>
                <v-menu top :close-on-click="true" :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-2">
                      {{ `${items_per_page} на стор.` }}
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item
                        v-for="(item, index) in items_per_page_list"
                        :key="index"
                        @click="setItemsPerPage(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn text depressed icon class="mr-1"
                       @click.stop="changePage(false)"
                       :disabled="items_per_page > items.length || page === pages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn text depressed icon
                       @click.stop="pageStartOrEnd(false)"
                       :disabled="page === pages"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {registrationTypeSelect} from "@/utils/icons";
import fillAPI from "@/utils/axios/fill_tables";
import {ALERT_SHOW} from "@/store/actions/alert";
import {GET_GENERAL_SETTINGS} from "@/store/actions/organization";

export default {
  name: "FillData",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      indicators: 'getFlatIndicatorTypes',
      settings: 'getGeneralSettings',
      current_month: 'getCurrentMonth'
    }),
    filtered_items() {
      return this.items.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  data() {
    return {
      fillAddressConfig: {
        city_id: null,
        streets: [],
        buildings: [],
        all_flats: false
      },
      items: [],
      selectedIndicators: [],
      create_date: null,
      registration_date: null,
      registration_type: null,
      registrationTypeSelect,
      formValid: false,
      cols: [],
      pages: 0,
      items_per_page: 19,
      page: 0,
      items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
      loading: false
    }
  },
  methods: {
    ...mapActions({
      getIndicators: FETCH_FLAT_INDICATOR_TYPES,
      getGeneralSetting: GET_GENERAL_SETTINGS,
    }),
    onFinishAddressFill(payload) {
      if (!payload) {
        this.fillAddressConfig = {
          city_id: null,
          streets: [],
          buildings: [],
          all_flats: false
        }
      } else {
        this.loading = true
        this.fillAddressConfig = Object.assign({}, payload)
        this.fillAddressConfig.indicators = this.selectedIndicators
        fillAPI.fill_data_table(this.fillAddressConfig)
          .then(response => response.data)
          .then(data => {
            this.cols = this.selectedIndicators.map(item => {
              let indicator = this.indicators.find(i => i.value === item)
              return Object.assign({}, { name: indicator.text, value: item, col: `indicator_${item}_value` })
            })
            this.pages = 0
            this.page = 0

            this.items = data.map((item, idx) => {
              return Object.assign(item, {row_num: idx + 1, row_idx: idx + 1})
            })
            this.setPageSplitter()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
            .finally(() => {
              this.loading = false
            })
      }
    },
    changeData(item, field) {
      const payload = {
        flat_id: item.flat_id,
        create_date: this.create_date,
        registration_type: this.registration_type,
        registration_date: this.registration_date,
        field: field,
        flat_owner_id: item['owner_id']
      }
      if (field === 'owner') {
        payload['item_id'] = item.owner_id || null
        payload['item_value_str'] = item.owner || ''
      }

      if (field === 'email') {
        payload['item_id'] = item.email_id || null
        payload['item_value_str'] = item.email || ''
      }

      if (field === 'phone') {
        payload['item_id'] = item.phone_id || null
        payload['item_value_str'] = item.phone || ''
      }

      if (field.indexOf('indicator_') !== -1) {
        const flat_indicator_id_field = field.replace('_value', '_id')

        payload['item_id_add'] = +field.split('_')[1]
        payload['item_id'] = item[flat_indicator_id_field]
        payload['item_value_int'] = item[field]
        payload['field'] = 'indicator_id'
      }

      fillAPI.change_data(payload)
          .then(response => response.data)
          .then(data => {
            if (field === 'owner') {
              item.owner_id = data
            }
            if (field === 'email') {
              item.email_id = data
            }
            if (field === 'phone') {
              item.phone_id = data
            }
            if (field.indexOf('indicator_') !== -1) {
              const flat_indicator_id_field = field.replace('_value', '_id')
              item[flat_indicator_id_field] = data
            }
          })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.items.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
  },
  created() {
    this.getIndicators()
    this.getGeneralSetting()
      .then(response => {
        if (response) {
          const general_square = (this.settings?.general_area_indicator || {}).value || null
          const living_person_indicator = (this.settings?.living_person_indicator || {}).value || null
          if (general_square !== null) {
            this.selectedIndicators.push(general_square)
          }
          if (living_person_indicator !== null) {
            this.selectedIndicators.push(living_person_indicator)
          }
          if (this.current_month) {
            this.create_date = this.current_month
          }
          this.registration_type = "empty"
        }
      })
    this.$nextTick()
        .then(() => {
          this.$refs.formFillData.validate()
        })
  }
}
</script>

<style scoped lang="scss">
input {
  width: 90%;
  display: block;
  outline: none;
  border-bottom: 1px solid rgba(128, 124, 124, 0.4);
  padding: 2px 4px 2px 0;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: var(--v-success-base);
    font-weight: 500;
  }
  &[disabled] {
    border-bottom: 2px dotted rgba(0, 0, 0, .4);
    background-color: #f1f1f1;
  }
}

.table-col {
  margin-right: 4px !important;
}
.table-body {
  .table-row {
    margin-bottom: 6px !important;
  }
}

.error-input {
  input {
    border-bottom: 2px solid #f09995;
  }
}
</style>